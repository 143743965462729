import React from "react";
import { Card, Table, Popconfirm, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { Link } from "react-router-dom";
import LineBreak from "../../LineBreak";
import ActivateCodeForm from "./ActivateCodeForm";
import { withTranslation } from "react-i18next";
import { getDatetimeWithTz } from "../../../helpers/productUtilities";

class UserCodes extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <Card
        key="user-codes"
        title={t("userpage.codes")}
        style={{ marginBottom: "30px" }}
      >
        <Table
          dataSource={this.props.userCodes.map((uc) => {
            uc.key = uc.id;
            return uc;
          })}
          pagination={{ defaultPageSize: 20 }}
          size="small"
        >
          <Column
            title={t("userpage.code_short")}
            dataIndex="code"
            key="code"
          />
          <Column
            title={t("userpage.desc")}
            dataIndex="desc"
            key="desc"
          />
          <Column
            title={t("userpage.details")}
            dataIndex="expiration_date"
            key="details"
            render={(v, r) => {
              const activationDate = getDatetimeWithTz(
                r.activation_date
              ).format("H:mm, DD.MM.YYYY");
              return (
                <>
                  {r.discount_percent
                    ? `–${r.discount_percent}%`
                    : `–${r.discount_cash} zł`}
                  <br />
                  {r.event_id ? (
                    <Link to={`/wydarzenie/${r.event_id}`}>
                      {t("userpage.event")} #{r.event_id}
                    </Link>
                  ) : null}
                  {r.video_id ? (
                    <Link to={`/wideo/${r.video_id}`}>
                      {t("userpage.video")} #{r.video_id}
                    </Link>
                  ) : null}
                  {!r.video_id && !r.event_id
                    ? t("userpage.any")
                    : null}
                  <br />
                  {t("userpage.activation_date")}: {activationDate}
                </>
              );
            }}
          />
          <Column
            title={t("userpage.used")}
            dataIndex="usage"
            key="usage"
            render={(v, r) => {
              return (
                <>
                  {r.used ? (
                    <Tag color="green">
                      {t("userpage.usage_date")}:{" "}
                      {getDatetimeWithTz(r.usage_date).format(
                        "H:mm, DD.MM.YYYY"
                      )}
                    </Tag>
                  ) : (
                    <Tag color="volcano">
                      {t("userpage.no_usage")}
                    </Tag>
                  )}
                </>
              );
            }}
          />

          <Column
            title="Akcje"
            key="action"
            render={(text, r) => (
              <>
                {r.used ? null : (
                  <Popconfirm
                    title={`${t("userpage.delete_code")}: ${r.code}?`}
                    onConfirm={() => {
                      this.props.deleteUserCode(r.code_id);
                    }}
                    okText={t("const.yes")}
                    cancelText={t("const.no")}
                  >
                    <DeleteOutlined
                      key="delete"
                      style={{ margin: "0 10px", fontSize: 20 }}
                    />
                  </Popconfirm>
                )}
              </>
            )}
          />
        </Table>
        <LineBreak additionalStyles={{ margin: "30px 0" }} />
        <ActivateCodeForm onSubmit={this.props.activateCode} />
      </Card>
    );
  }
}

export default withTranslation()(UserCodes);
