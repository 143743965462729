import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Checkbox, notification, Skeleton } from "antd";
import { getConfig } from "../../../actions/appConfigActions";
import {
  buyGift,
  clearGiftPaymentError,
  getGiftSettings,
} from "../../../actions/giftsActions";
import "./PaymentPage.scss";
import Wrapper from "../../wrappers/Wrapper";
import InvoiceForm from "./InvoiceForm";
import {
  allowCompanyInvoices,
  allowInvoices,
  paymentCheckbox,
  paymentCheckboxEn,
  paymentVatInfo,
  paymentVatInfoEn,
} from "../../../theme-variables";
import tc from "../../../helpers/translateContent";

class GiftPaymentPage extends React.Component {
  state = {
    ticketType: "standard",
    needInvoice: false,
    additionalCb: false,
    additionalCbError: false,
    invoiceData: {
      street: this.props.user.street,
      postal_code: this.props.user.postal_code,
      country_code: this.props.user.country_code,
      city: this.props.user.city,
      state: this.props.user.state,
      nip: this.props.user.nip,
      company: this.props.user.company,
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
    },
    invoiceType: "person",
  };
  componentDidMount() {
    this.props.getConfig();
    this.props.getGiftSettings();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.giftPayment.loading &&
      !this.props.giftPayment.success &&
      this.props.giftPayment.error
    ) {
      notification.error({
        message: this.props.t("const.error_occured"),
        description: this.props.giftPayment.error,
      });
      this.props.clearGiftPaymentError();
    }
    return null;
  };
  componentWillUnmount = () => {
    this.props.clearGiftPaymentError();
  };
  renderGiftDescription = () => {
    const { giftConfig } = this.props;
    const {
      gift_label,
      gift_label_en,
      gift_description,
      gift_description_en,
    } = giftConfig;
    return (
      <>
        <h2>
          {tc(gift_label, "title", [
            {
              language_code: "en",
              description: gift_label_en,
            },
          ])}
        </h2>
        <p
          className="product-description"
          dangerouslySetInnerHTML={{
            __html: tc(gift_description, "title", [
              {
                language_code: "en",
                description: gift_description_en,
              },
            ]),
          }}
        />
      </>
    );
  };
  renderPrice = () => {
    const { giftConfig } = this.props;
    return (
      <div className="final-price">
        {giftConfig.gift_price} zł
        <span>
          {this.props.i18n.language === "pl"
            ? paymentVatInfo
            : paymentVatInfoEn}
        </span>
      </div>
    );
  };
  getReasonWhyNotAvailable = () => {
    const { t, giftConfig } = this.props;
    const { any_gift_available, gift_expiration, gift_price } = giftConfig;
    let reason = null;
    if (
      !any_gift_available ||
      (gift_expiration && moment(gift_expiration).isBefore(moment())) ||
      !gift_price
    )
      reason = t("paymentpage.any_gift_payment");

    if (!reason) return null;
    return (
      <Button className="payment-button red" disabled>
        {reason}
      </Button>
    );
  };
  renderPaymentButton = () => {
    const { giftConfig, t } = this.props;
    return (
      <>
        <Button
          type="primary"
          className={`payment-button white`}
          loading={giftConfig.loading}
          onClick={() => this.handlePayment()}
        >
          {t("paymentpage.go_to_payment")}
        </Button>
        <small>{t("paymentpage.buy_as_a_gift_info")}</small>
      </>
    );
  };
  onInvoiceCbChange = (checked) => {
    this.setState({ needInvoice: checked });
  };
  onAdditionalCbChange = (checked) => {
    this.setState({ additionalCb: checked, additionalCbError: false });
  };
  changeInvoiceData = (newState) => {
    this.setState({ invoiceData: { ...this.state.invoiceData, ...newState } });
  };
  changeInvoiceType = (val) => {
    this.setState({ invoiceType: val });
  };
  handlePayment = () => {
    const {
      postal_code,
      city,
      state,
      country_code,
      street,
      company,
      firstname,
      lastname,
      nip,
    } = this.state.invoiceData;
    const { invoiceType, needInvoice, additionalCb } = this.state;
    const { t } = this.props;

    if (!additionalCb) {
      this.setState({ additionalCbError: true });
      return;
    }

    let finalInvoiceData = {};
    if (needInvoice) {
      let newState = {};
      if (allowCompanyInvoices && invoiceType === "company") {
        if (!company) newState.company_error = t("form.validate.required");
        else if (company.length > 100)
          newState.company_error = t("form.validate.wrong");
        if (!nip) newState.nip_error = t("form.validate.required");
        else if (nip.length > 100 || !/^[0-9]*$/.test(nip))
          newState.nip_error = t("form.validate.wrong");
      } else {
        if (!firstname) newState.firstname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(firstname))
          newState.firstname_error = t("form.validate.wrong");
        if (!lastname) newState.lastname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(lastname))
          newState.lastname_error = t("form.validate.wrong");
      }
      if (!postal_code)
        newState.postal_code_error = t("form.validate.required");
      if (!city) newState.city_error = t("form.validate.required");
      if (!state) newState.state_error = t("form.validate.required");
      if (!country_code)
        newState.country_code_error = t("form.validate.required");
      if (!street) newState.street_error = t("form.validate.required");
      if (
        postal_code &&
        (postal_code.length > 10 || /<[a-z/][\s\S]*>/.test(postal_code))
      )
        newState.postal_code_error = t("form.validate.wrong_input_max10");
      if (city && (city.length > 30 || /<[a-z/][\s\S]*>/.test(city)))
        newState.city_error = t("form.validate.wrong_input_max30");
      if (state && (state.length > 30 || /<[a-z/][\s\S]*>/.test(state)))
        newState.state_error = t("form.validate.wrong_input_max30");
      if (
        country_code &&
        (country_code.length > 2 || /<[a-z/][\s\S]*>/.test(country_code))
      )
        newState.country_code_error = t("form.validate.wrong_input_max2");

      if (Object.keys(newState).length > 0) {
        this.setState({
          invoiceData: { ...this.state.invoiceData, ...newState },
        });
        return;
      }

      finalInvoiceData = {
        postal_code,
        city,
        state,
        country_code,
        street,
        company,
        firstname,
        lastname,
        nip,
        invoiceType: allowCompanyInvoices ? invoiceType : "person",
      };
    }
    this.props.buyGift(null, "any", finalInvoiceData);
  };
  render() {
    const { t, giftConfig } = this.props;
    const {
      needInvoice,
      additionalCb,
      additionalCbError,
      invoiceData,
      invoiceType,
    } = this.state;
    const productPrice = giftConfig.gift_price;
    if (
      giftConfig &&
      Object.keys(giftConfig).length > 0 &&
      !this.getReasonWhyNotAvailable()
    )
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            <p>
              {this.props.user.firstname}, {t("paymentpage.great_choice")} – 
              {t("paymentpage.ticket_gift")}:
            </p>
            {this.renderGiftDescription()}
            <div className="payment-checkboxes">
              {productPrice > 0 && allowInvoices ? (
                <Checkbox
                  checked={needInvoice}
                  onChange={(e) => this.onInvoiceCbChange(e.target.checked)}
                  style={{ marginBottom: 20 }}
                >
                  {t("videodesc.need_invoice")}
                </Checkbox>
              ) : null}
              {needInvoice && allowInvoices ? (
                <InvoiceForm
                  changeInvoiceData={this.changeInvoiceData}
                  changeInvoiceType={this.changeInvoiceType}
                  invoiceData={invoiceData}
                  invoiceType={invoiceType}
                />
              ) : null}
              {paymentCheckbox ? (
                <Checkbox
                  checked={additionalCb}
                  onChange={(e) => this.onAdditionalCbChange(e.target.checked)}
                >
                  <span
                    className={additionalCbError ? `cb-error` : ""}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.i18n.language === "pl"
                          ? paymentCheckbox
                          : paymentCheckboxEn || paymentCheckbox,
                    }}
                  />
                </Checkbox>
              ) : null}
            </div>
            <div className="payment-price">
              {this.renderPrice()}
              {this.renderPaymentButton()}
            </div>
          </div>
        </Wrapper>
      );
    else if (
      giftConfig &&
      Object.keys(giftConfig).length > 0 &&
      this.getReasonWhyNotAvailable()
    )
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            {this.renderGiftDescription()}
            {this.getReasonWhyNotAvailable()}
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    giftPayment: state.gifts || { loading: false },
    user: state.auth.user,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    giftConfig: state.gifts.config,
  };
};

export default connect(mapStateToProps, {
  buyGift,
  clearGiftPaymentError,
  getConfig,
  getGiftSettings,
})(withTranslation()(GiftPaymentPage));
