import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import "./EventCard.scss";
import {
  checkEventStatusByDate,
  getEventTimeDescription,
  renderProductPrice,
} from "../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";
import tc from "../../../helpers/translateContent";

class EventCard extends React.Component {
  renderPaymentButton = () => {
    const { event, userRole, t } = this.props;

    const paymentProcessing =
      event.paymentStatus === "PENDING" ||
      event.paymentStatus === "WAITING_FOR_CONFIRMATION";

    const hasAccess =
      (Number.parseFloat(event.event_price) === 0 &&
        event.available_tickets < 1) ||
      event.paymentStatus === "COMPLETED" ||
      userRole === "admin" ||
      userRole === "premium";

    const eventStatus = checkEventStatusByDate(
      event.date_from,
      event.date_to,
      event.full_day,
      event.time_from,
      event.time_to
    );

    if (paymentProcessing)
      return (
        <Button className="orange" disabled>
          {t("button.processing")}
        </Button>
      );

    if (eventStatus !== -1 && !hasAccess) {
      if (event.enable_tickets) {
        if (event.available_tickets > 0 && !event.ticketsAvailable)
          return (
            <Link to={`/wydarzenie/${event.slug || event.id}`}>
              <Button className="red" disabled>
                {t("button.no_tickets")}
              </Button>
            </Link>
          );
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <Button className="black">
              {Number.parseFloat(event.event_price) === 0
                ? t("button.get_access")
                : t("button.buy")}
            </Button>
          </Link>
        );
      } else return null;
    }

    switch (eventStatus) {
      case -1:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <Button className="orange">{t("button.ended")}</Button>
          </Link>
        );
      case 0:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <Button className="blue">{t("button.soon")}</Button>
          </Link>
        );
      case 1:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}?ogladaj=true`}>
            <Button className="green">{t("button.watch")}</Button>
          </Link>
        );
      default:
        break;
    }
  };
  render() {
    const { event, t, userRole } = this.props;
    const hasAccess =
      (Number.parseFloat(event.event_price) === 0 &&
        event.available_tickets < 1) ||
      event.paymentStatus === "COMPLETED" ||
      userRole === "admin" ||
      userRole === "premium";
    return (
      <div
        className={`event-card ${
          event.published === 1 ? "" : "not-published"
        } ${event.dashboard_hidden === 1 ? "hidden" : ""}`}
      >
        <div className="event-date">
          {getEventTimeDescription(
            event.full_day,
            event.date_from,
            event.date_to,
            event.time_from,
            event.time_to,
            t,
            event.hide_time_to
          )}
          {event.published !== 1 || event.dashboard_hidden === 1 ? (
            <br />
          ) : null}
          {event.published === 1 ? "" : "nieopublikowany"}
          {event.dashboard_hidden === 1 ? "ukryty" : ""}
        </div>
        <div className="event-title">
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            {tc(event.title, "title", event.translations)}
            {event.subtitle ? (
              <span className="event-subtitle">
                {tc(event.subtitle, "subtitle", event.translations)}
              </span>
            ) : null}
          </Link>
        </div>
        <div className="event-price">
          {event.enable_tickets
            ? renderProductPrice(
                event.event_price,
                t,
                hasAccess ? undefined : event.newPrice
              )
            : null}
        </div>
        <div className="event-payment-status">{this.renderPaymentButton()}</div>
      </div>
    );
  }
}

export default withTranslation()(EventCard);
