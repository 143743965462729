import vod, { tokenConfig } from "../apis/vod";
import {
  BUY_GIFT_LOADING,
  BUY_GIFT_SUCCESS,
  BUY_GIFT_FAILURE,
  GET_GIFT_ORDER_SUCCESS,
  GET_GIFT_ORDER_FAILURE,
  GET_GIFT_ORDERS_SUCCESS,
  GET_GIFT_ORDERS_FAILURE,
  CLEAR_GIFT_PAYMENT_ERROR,
  GIFT_CONFIG_LOADING,
  GET_GIFT_CONFIG_SUCCESS,
  GET_GIFT_CONFIG_FAILURE,
  GIFT_FORM_ERRORS,
  UPDATE_GIFT_CONFIG_FAILURE,
  UPDATE_GIFT_CONFIG_SUCCESS,
  CLEAR_GIFT_CONFIG_STATUS,
} from "./_types";
import handleErrors from "./handleErrors";
import history from "../history";
import { facebookPixel } from "../theme-variables";
import ReactPixel from "react-facebook-pixel";

export const buyGift = (productId, productType, props) => (
  dispatch,
  getState
) => {
  dispatch({
    type: BUY_GIFT_LOADING,
    payload: productId,
  });
  vod
    .post(
      `/api/gifts/`,
      { ...props, productSlug: `${productType}-${productId}` },
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: BUY_GIFT_SUCCESS });
      if (facebookPixel && res.data.payu && res.data.order) {
        const { order } = res.data;
        ReactPixel.track("InitiateCheckout", {
          content_ids: [order.product_id],
          content_category: order.type,
          content_type: "product",
          contents: order.contents,
          currency: "PLN",
          value: parseFloat(order.value),
        });
      }
      if (res.data.payu) window.location.href = res.data.payu.redirectUri;
      else history.go(0);
    })
    .catch((err) => {
      handleErrors(err, dispatch, BUY_GIFT_FAILURE);
    });
};

export const getOrder = (extOrderId) => (dispatch, getState) => {
  vod
    .get(`/api/gifts/${extOrderId}`, {
      headers: {
        "x-auth-token": getState().auth.token,
      },
    })
    .then((res) => {
      dispatch({ type: GET_GIFT_ORDER_SUCCESS, payload: res.data.order });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GIFT_ORDER_FAILURE);
    });
};

export const getUserOrders = () => (dispatch, getState) => {
  vod
    .get(`/api/gifts/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_GIFT_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GIFT_ORDERS_FAILURE);
    });
};

export const getOrders = (props) => (dispatch, getState) => {
  vod
    .get(`/api/gifts/`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GIFT_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GIFT_ORDERS_FAILURE);
    });
};

export const updateGiftSettings = (props) => (dispatch, getState) => {
  vod
    .put(`/api/gifts/settings`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_GIFT_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_GIFT_CONFIG_FAILURE, GIFT_FORM_ERRORS);
    });
};

export const getGiftSettings = (props) => (dispatch, getState) => {
  dispatch({ type: GIFT_CONFIG_LOADING });
  vod
    .get(`/api/gifts/settings`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GIFT_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GIFT_CONFIG_FAILURE, GIFT_FORM_ERRORS);
    });
};

export const clearGiftPaymentError = () => (dispatch) => {
  dispatch({ type: CLEAR_GIFT_PAYMENT_ERROR });
};

export const clearGiftConfigStatus = () => (dispatch) => {
  dispatch({ type: CLEAR_GIFT_CONFIG_STATUS });
};
