import React from "react";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import Node from "./Node";
import { withTranslation } from "react-i18next";
import Wrapper from "../../wrappers/Wrapper";
import history from "../../../history";
import tc from "../../../helpers/translateContent";
import vod from "../../../apis/vod";
import {
  checkEventStatusByDate,
  getDatetimeRange,
  getEventTimeDescription,
  getTimeToStart,
} from "../../../helpers/productUtilities";
import TagManager from "react-gtm-module";
import _ from "lodash";

class NoAuthEventPage extends React.Component {
  state = { event: null, loading: true };
  fetchEvent = () => {
    vod
      .get("/external_api/event/" + this.eventId)
      .then((res) => {
        this.setState({ event: res.data.event, loading: false });
      })
      .catch((err) => console.error(err));
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.event !== this.state.event &&
      !!this.state.event &&
      !this.state.loading
    ) {
      const dataLayer = {
        event: "productView",
        page_type: "event",
        page: _.pick(
          this.state.event,
          "id",
          "title",
          "description",
          "subtitle",
          "event_price",
          "additional_desc",
          "age_restriction",
          "duration",
          "date_from",
          "date_to",
          "time_from",
          "time_to",
          "full_day",
          "video_id"
        ),
      };
      console.log({ ...dataLayer, authenticated: false });
      TagManager.dataLayer({ ...dataLayer, authenticated: false });
    }
  }
  componentDidMount() {
    this.eventId = this.props.match.params.id;
    if (this.props.autenticated) history.push(`/wydarzenie/${this.eventId}`);
    this.fetchEvent(this.eventId);
  }
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getNearestDateTime = () => {
    const e = this.state.event;
    const nearestDate = getTimeToStart(
      e.date_from,
      e.date_to,
      e.time_from,
      e.time_to,
      e.full_day
    );
    return nearestDate;
  };
  render() {
    const { t } = this.props;
    const { loading, event } = this.state;
    if (!loading && event) {
      const {
        title,
        description,
        date_from,
        date_to,
        time_from,
        time_to,
        full_day,
        event_price,
        duration,
        age_restriction,
        teaser_link,
        available,
        gallery,
        image_link,
        translations,
        videoTranslations,
        additional_desc,
        enable_tickets,
        ticketsAvailable,
        hide_time_to,
      } = event;
      const { dateDesc, timeDesc } = getEventTimeDescription(
        full_day,
        date_from,
        date_to,
        time_from,
        time_to,
        t,
        hide_time_to,
        true
      );
      const eventStatus = checkEventStatusByDate(
        date_from,
        date_to,
        full_day,
        time_from,
        time_to
      );
      const countdownDate = this.getNearestDateTime();
      let paymentButtonProps = null;
      if (eventStatus !== -1 && !ticketsAvailable)
        paymentButtonProps = {
          color: "red",
          text: t("videodesc.no_tickets"),
          disabled: true,
        };
      else if (eventStatus !== -1 && enable_tickets)
        paymentButtonProps = {
          color: "white",
          text:
            Number.parseFloat(event_price) === 0
              ? t("videodesc.login")
              : t("videodesc.buy"),
          disabled: false,
          onClick: () => {
            history.push(
              `/logowanie?redirect=/wydarzenie/${this.props.match.params.id}`
            );
          },
        };
      return (
        <Wrapper>
          <Node
            title={tc(title, "title", translations)}
            time={timeDesc}
            date={dateDesc}
            countdownText={t("videodesc.video_from")}
            countdownDate={countdownDate}
            description={tc(description, "description", videoTranslations)}
            additionalDesc={tc(
              additional_desc,
              "additional_desc",
              videoTranslations
            )}
            paymentButton={paymentButtonProps}
            price={{
              price: event_price,
            }}
            duration={duration}
            ageRestriction={age_restriction}
            teaserLink={tc(teaser_link, "teaser_link", videoTranslations)}
            available={available}
            availableText={t("videodesc.event_on")}
            mainImage={image_link}
            gallery={gallery}
          />
        </Wrapper>
      );
    } else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
  };
};

export default connect(mapStateToProps)(withTranslation()(NoAuthEventPage));
