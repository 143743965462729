import React from "react";
import { Field, reduxForm } from "redux-form";
import { TagOutlined } from "@ant-design/icons";
import i18n from "../../../i18n";
import Input from "../../form-components/Input";
import Button from "../../form-components/Button";
import { withTranslation } from "react-i18next";

class ActivateCodeForm extends React.Component {
  state = { code: "" };
  onSubmit = (formValues) => {
    this.props.onSubmit({
      code: this.state.code.trim(),
    });
  };
  onCodeChange = (e) => {
    console.log(e);
    this.setState({ code: e.target.value });
  };
  render() {
    const { t } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Field
          allowClear
          name="code"
          value={this.state.code}
          onChange={this.onCodeChange}
          component={Input}
          required={true}
          prefix={<TagOutlined className="site-form-item-icon" />}
          placeholder={t("userpage.code")}
        />
        <Button
          type="primary"
          htmlType="submit"
          style={{
            maxWidth: "300px",
            marginBotom: 0,
            marginLeft: 15,
          }}
        >
          {t("userpage.activate")}
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.code) errors.code = i18n.t("form.validate.required");

  return errors;
};

export default reduxForm({ form: "userCodeForm", validate })(
  withTranslation()(ActivateCodeForm)
);
